<template>
  <div style="width: auto">
    <!-- 面包屑导航 -->
    <Breadcrumb :breadList="breadList">
    </Breadcrumb>

    <a-card style="width: auto">
      <template slot="title">
        <span>订单编号: {{ this.detail.orderNo }}</span>
        <span style="margin-left: 40px">下单时间：{{ this.detail.gmtCreate }}</span>
        <span style="margin-left: 40px">状态：{{ this.detail.orderStatus.name }}</span>
      </template>
      <div style="margin-top: 10px">
        <span class="font-small">付款信息</span>
      </div>
      <!--付款信息  -->
      <div class="table-layout">
        <a-row>
          <a-col :span="5" class="table-cell-title">支付方式</a-col>
          <a-col :span="3" class="table-cell-title">支付金额</a-col>
          <a-col :span="8" class="table-cell-title">支付信息</a-col>
          <a-col :span="3" class="table-cell-title">状态</a-col>
          <a-col :span="5" class="table-cell-title">时间</a-col>
        </a-row>
        <a-row v-for="(item,index) in this.detail.orderPay" :key="index">
          <a-col :span="5" class="table-cell">
            {{ item.payCompanyCode|formatPayCompanyCode }}
            <span style="margin-left: 20px">{{ item.event|formatEvent }}</span>
          </a-col>
          <a-col :span="3" class="table-cell" style="color:red;text-align: center"><span>￥</span>{{ item.paid }}</a-col>
          <a-col :span="8" class="table-cell flex-row">
             {{ item.payCompanyCode|formatPayCompanyCodeStatus }}
            <span style="margin-left: 20px">{{ item.outPayNo }}</span>
            <!-- <div style="flex:1">
              {{ item.payCompanyCode|formatPayCompanyCodeStatus }}<br/>
              {{ item.outPayNo }}
            </div> -->
            <div style="width:70px;" v-if="item.voucherUrl!==''">
              <viewer>
                <a-avatar shape="square" size="50" style="float: right"
                          :src="item.voucherUrl"/>
              </viewer>
            </div>

          </a-col>
          <a-col :span="3" class="table-cell" style="text-align: center">{{ item.status|formatStatus }}</a-col>
          <a-col :span="5" class="table-cell">
            <span v-if="item.gmtPay">支付时间：{{ item.gmtPay }}</span>
          </a-col>
        </a-row>
      </div>
      <!--其他信息  -->
      <div class="table-layout">
        <a-row>
          <a-col :span="8" class="table-cell-title2">收件人信息</a-col>
          <a-col :span="8" class="table-cell-title2">
            配送信息
            <span v-if="this.detail.deliverStatus==='deliver' || this.detail.status==='complete'">
              (
                <a v-if="this.detail.deliveryType==='express'"
                  @click="$refs.dialog2.init(detail);$refs.dialog2.isShow = true">物流信息</a>
                <a v-if="this.detail.deliveryType==='fetch'"
                  @click="$refs.dialog3.init(detail);$refs.dialog3.isShow = true">取货记录</a>
              )
            </span>
          </a-col>
          <a-col :span="8" class="table-cell-title2">发票信息</a-col>
        </a-row>
        <a-row>
          <a-col :span="8" class="table-cell2">
            <div style="margin-top: 2px" v-if="this.detail.orderUser!=null">
              账号：{{ this.detail.orderUser.tel }}
              <span style="margin-left: 10px">{{ this.detail.orderUser.userName }}</span>
            </div>
            <div v-if="this.detail.deliveryType=== 'fetch'">自提：自提</div>
            <span v-if="this.detail.deliveryType === 'express' && this.detail.orderAddress != null">
              <div>收货人：{{ this.detail.orderAddress.addresseeName }}</div>
              <div>收货地址：{{ this.detail | formatAddress }}</div>
              <div>详细地址：{{ this.detail.orderAddress.address }}</div>
              <div style="margin-top: 2px">联系电话：{{ this.detail.orderAddress.addresseeTel }}</div>
              <div>买家留言：<div style="color:#4f9efa;"></div></div>
            </span>
          </a-col>
          <a-col :span="8" class="table-cell2">
            <div v-if="this.detail.deliveryType!=null">配送方式：{{ this.detail | formatDeliveryType }}</div>
            <span v-if="this.detail.shipping!=null && this.detail.deliveryType === 'express'">
             <div>快递公司：{{ this.detail.shipping.expressName }}</div>
             <div>运单号：{{ this.detail.shipping.expressNo }}</div>
             <div>运费：{{ this.detail.shippingFee }}</div>
           </span>
          </a-col>
          <a-col :span="8" class="table-cell2">
            <span v-if="this.detail.invoice!=null">
              <div>类型：{{ this.detail.invoice.nameType === 'compan' ? '企业' : '个人' }}</div>
              <div v-if="this.detail.invoice.nameType==='compan'">抬头信息：{{ this.detail | formatText }}</div>
              <div v-if="this.detail.invoice.nameType==='person'">抬头信息：{{ this.detail.invoice.name }}</div>
              <div>发票类型：{{ this.detail.invoice.invoiceType === 1 ? '电子' : '纸质' }}</div>
              <div>发票内容：{{ this.detail.invoice.content }}</div>
              <div v-if="this.detail.invoice.invoiceType === 1">接收邮箱：{{ this.detail | formatText3}}</div>
              <div v-if="this.detail.invoice.invoiceType === 2">发票邮寄地址：{{ this.detail | formatText2 }}</div>
            </span>
          </a-col>
        </a-row>
      </div>
      <!--商品信息  -->
      <div style="margin-top: 10px">
        <span class="font-small">商品信息</span>
      </div>
      <div class="table-layout">
        <a-row>
          <a-col :span="13" class="table-cell-title">商品</a-col>
          <a-col :span="3" class="table-cell-title">单价</a-col>
          <a-col :span="3" class="table-cell-title">优惠</a-col>
          <a-col :span="2" class="table-cell-title">数量</a-col>
          <a-col :span="3" class="table-cell-title">实付</a-col>
          <!-- <a-col :span="5" class="table-cell-title">操作</a-col> -->
        </a-row>
        <a-row v-for="(item,index) in this.detail.orderItems" :key="index">
          <a-col :span="13" class="table-cell3">
            <a  @click="zoom(item.imgUrl)">
              <a-avatar shape="square"
                    style="width:84px;height:84px;float: left"
                    :src="item.imgUrl" />
            </a>
            <div style="margin-top: 20px;margin-left: 90px;">
              {{ item.productName| formatLongText3 }}
            </div>
            <div style="margin-top: 2px;margin-left: 90px;">型号：{{ item.spec }}</div>
          </a-col>
          <a-col :span="3" class="table-cell3" style="color:red;">
            <div style="margin-top: 20px">￥{{ item.price }}</div>
          </a-col>
          <a-col :span="3" class="table-cell3">
              <div style="margin-top: 20px;color: red" >￥{{item.discount}}</div>
          </a-col>
          <a-col :span="2" class="table-cell3">
            <div style="margin-top: 20px">{{ item.qty }}</div>
          </a-col>
          <a-col :span="3" class="table-cell3">
            <div style="margin-top: 20px;color: red" >￥{{item.paid}}</div>
          </a-col>
        </a-row>
        <a-modal
          :footer="null"
          :visible="visible"
          @ok="handleOk"
          @cancel="handleCancel"
        >
          <img style="width:100%" :src="goodsImag"/>
        </a-modal>
      </div>
      <!--商品信息-->
      <div style="margin-top: 10px">
        <a-row >
          <a-col :span="8"></a-col>
          <a-col :span="3"></a-col>
          <a-col :span="3"></a-col>
          <a-col :span="2"></a-col>
          <a-col :span="3"></a-col>
          <a-col :span="5">
            <span >商品总金额：￥{{ this.detail.totalPrice }}</span>
            <br/>
            <span >运费：￥{{ this.detail.shippingFee }}</span>
            <br/>
            <span >优惠：￥{{ this.detail.totalDiscount }}</span>
            <br/>
            <span >应付总额：<span style="color: red">￥{{ this.detail.totalPaid }}</span></span>
          </a-col>
        </a-row>
      </div>
    </a-card>
    <logistics-item ref="dialog2" @save="getOrderDetail"></logistics-item>
    <fetch-item ref="dialog3" @save="getOrderDetail"></fetch-item>
  </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'
import FetchItem from './dialog/fetchItem.vue'
import LogisticsItem from './dialog/logisticsItem.vue'

export default {
  components: {
    Breadcrumb,
    FetchItem,
    LogisticsItem
  },
  name: 'orderDetail',
  data () {
    return {
      visible: false,
      goodsImag: '',
      accountPad: 0,
      orderNo: null,
      detail: {},
      breadList: [
        {
          name: '订单列表',
          url: '/order/list'
        },
        {
          name: '订单详情',
          url: ''
        }
      ]
    }
  },
  created () {
    this.orderNo = this.$route.query.orderNo
    this.getOrderDetail()
  },
  filters: {
    formatDeliverStatus (v) {
      if (v.deliverStatus === 'outbound') {
        return '待出货'
      } else if (v.deliverStatus === 'waitDeliver') {
        return '待发货'
      } else if (v.deliverStatus === 'deliver' && v.deliveryType === 'fetch') {
        return '待提货'
      } else if (v.deliverStatus === 'deliver' && v.deliveryType === 'express') {
        return '待收货'
      } else if (v.deliverStatus === 'received') {
        return '已收货'
      } else {
        return '无配送'
      }
    },
    formatPayStatus (v) {
      if (v === 'waitPay') {
        return '待支付'
      } else if (v === 'pay') {
        return '已支付'
      } else if (v === 'portionPay') {
        return '部分支付'
      }
    },
    formatPayCompanyCode (v) {
      if (v === 'alipay' || v === 'wechat') {
        return '线上支付'
      } else {
        return '线下支付'
      }
    },
    formatEvent (v) {
      if (v === 'pay') {
        return '普通'
      } else if (v === 'presellPay') {
        return '定金'
      } else if (v === 'finalPay') {
        return '尾款'
      }
    },
    formatPayCompanyCodeStatus (v) {
      if (v === 'alipay') {
        return '支付宝支付'
      } else if (v === 'wechat') {
        return '微信支付'
      } else if (v === 'voucher') {
        return '凭证支付'
      } else if (v === 'offlinePay') {
        return '线下支付'
      }
    },
    formatStatus (v) {
      if (v === 'waitPay') {
        return '待支付'
      } else if (v === 'waitAudit') {
        return '待审核'
      } else if (v === 'pay') {
        return '已支付'
      } else if (v === 'fail') {
        return '审核失败'
      }
    },
    formatLongText (v) {
      if (v === undefined || v === null || v === '') {
        return '暂无'
      } else if (v.length > 10) {
        return v.substr(0, 10) + '...'
      } else {
        return v
      }
    },
    formatLongText2 (v) {
      if (v === undefined || v === null || v === '') {
        return '暂无'
      } else if (v.length > 16) {
        return v.substr(0, 16) + '...'
      } else {
        return v
      }
    },
    formatLongText3 (v) {
      if (v === undefined || v === null || v === '') {
        return '暂无'
      } else {
        return v
      }
    },
    formatAddress (v) {
      return v.orderAddress.provinceName + ' ' + v.orderAddress.cityName + ' ' + v.orderAddress.districtName
    },
    formatDeliveryType (v) {
      if (v.deliveryType === 'express') {
        return '快递'
      } else if (v.deliveryType === 'fetch') {
        return '自提'
      }
    },
    formatText (v) {
      return v.invoice.name + ' ' + v.invoice.taxpayerNo + ' ' + v.invoice.tel + ' ' + v.invoice.bankName + ' ' + v.invoice.bankNo
    },
    formatText2 (v) {
      return v.invoice.addresseeName + ' ' + v.invoice.addresseeTel + ' ' + v.invoice.addresseeAddress
    },
    formatText3 (v) {
      return v.invoice.email + ' ' + v.invoice.invoiceUrl
    }
  },
  methods: {
    async getOrderDetail () {
      const result = await this.$store.dispatch('http', {
        api: 'orderDetail',
        query: {
          orderNo: this.orderNo
        },
        complete: () => {

        }
      })
      this.detail = result
      this.accountPad = result.accountPad
    },
    handleOk (e) {
      setTimeout(() => {
        this.visible = false
      })
      this.queryList()
    },
    handleCancel (e) {
      this.visible = false
    },
    zoom (scope) {
      this.visible = true
      this.goodsImag = scope
    }
  }
}
</script>

<style scoped>
.font-small {
  font-size: 18px;
  color: #606266;
  font-weight: bold;
}

.font-small2 {
  font-size: 15px;
  color: #606266;
  margin-top: 2px;
}

.table-layout {
  margin-top: 10px;
  border-left: 1px solid #DCDFE6;
  border-top: 1px solid #DCDFE6;
}

.table-cell-title {
  border-right: 1px solid #DCDFE6;
  border-bottom: 1px solid #DCDFE6;
  padding: 10px;
  background: #F2F6FC;
  text-align: center;
  font-size: 14px;
  color: #303133;
}

.table-cell-title2 {
  border-right: 1px solid #DCDFE6;
  border-bottom: 1px solid #DCDFE6;
  font-weight: bold;
  padding: 10px;
  background: #F2F6FC;
  text-align: center;
  font-size: 14px;
  color: #303133;
}

.table-cell-title3 {
  border-right: 1px solid #DCDFE6;
  border-bottom: 1px solid #DCDFE6;
  padding: 50px;
  /*background: #F2F6FC;*/
  /*text-align: center;*/
  font-size: 14px;
  color: #303133;
  float: right;
}

.table-cell {
  height: 60px;
  line-height: 20px;
  border-right: 1px solid #DCDFE6;
  border-bottom: 1px solid #DCDFE6;
  padding: 10px;
  font-size: 14px;
  color: #606266;
  /*text-align: center;*/
  overflow: hidden;
}

.table-cell2 {
  height: 160px;
  border-right: 1px solid #DCDFE6;
  border-bottom: 1px solid #DCDFE6;
  padding: 10px;
  font-size: 13px;
  color: #606266;
  /*text-align: center;*/
  overflow: hidden;
}

.table-cell3 {
  height: 105px;
  border-right: 1px solid #DCDFE6;
  border-bottom: 1px solid #DCDFE6;
  padding: 10px;
  font-size: 13px;
  color: #606266;
  /* text-align: center; */
  overflow: hidden;
}
</style>
